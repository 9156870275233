export default {
  tunnelHostUrl: "https://demo-tarification.aabvie.net",
  selectNotFoundData: 'Aucun élément trouvé',
  selectLabel: "Choisir",
  selectedLabel: "Sélectionné",
  deselectLabel: "Désélectionner",
  denied: "impossible de modifier cet élément",
  APIKEYKKIAPAY: "129b74c01d2711eba7b7ed071ed3502f",
  SANDBOXAPIKEYKKIAPAY: "148591801d2711eb8d57cfb99f32798d",
}

